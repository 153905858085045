<template>
    <div class="section-content pt-4" :class="{ 'mt-10': $vuetify.breakpoint.xs, 'mt-n3': $vuetify.breakpoint.sm }">
    <SellRateSetRateModal v-if="hasPricesData" />
    <template v-if="hasSpaceData">
        <rate-management-spaces @setPreviousData="setPreviousData($event)" :key="0" type="Space Group" />
    </template>
    <hb-header v-show="!hasSpaceData" full class="spaces-header" :divider="false">
        <hb-page-header
            title="Spaces"
            description="Configure and manage spaces for the selected properties.">
        </hb-page-header>
    </hb-header>
    <!-- BCT: Added Action option 'add_spaces' in actions_panel -->
    <div v-show="!hasSpaceData" style="flex: 1" :class="{'filter-adjustment-hb-report': (current_tab == 'list' && $vuetify.breakpoint.width <= 600), 'filter-adjustment-hb-report-map': (current_tab == 'map' &&  $vuetify.breakpoint.width <= 600)}">
          <hb-report
            :key="report_key"
            :report_type="report_type"
            :actions_panel="actions_panel_list"
            show_views
            right_click
            row_click="unit_view"
            show_search
            :left_slot="report_type !== 'spaces'"
            @rowClicked="viewUnit"
            @onTabChange="onTabChange"
            :show_default_report_option="true"
            :active-tab="current_tab"
        >
        </hb-report>
     </div>
     </div>
</template>

<script type="text/babel">
import { mapMutations, mapGetters, mapActions } from "vuex"
import RateManagementSpaces from "../revenue_management/utils/Spaces.vue";
import HbReport from '../assets/HummingbirdReportViewer.vue';
import { EventBus } from '../../EventBus.js';
import api from "../../assets/api.js";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import SellRateSetRateModal from "../revenue_management/rate_management/SellRateSetRateModal.vue"

export default {
    name: "Units",
    data() {
        return {
            report_key: 0,
            report_type: 'spaces',
            current_tab: 'list',
        }
    },
    created() {
        EventBus.$on('refetch_data', () => this.fetchData());
    },
    destroyed() {
        EventBus.$off('refetch_data', () => this.fetchData());
    },
    mixins: [notificationMixin],
    mounted() {
        this.$store.commit("reportStore/setDynamicRunParam", {
            propertyIDArray: this.property ? [this.property] : undefined,
        });
    },
    provide() {
        return {
            fetchData: this.fetchData
        };
    },
    components: {
        HbReport,
        RateManagementSpaces,
        SellRateSetRateModal,
    },
    filters: {
    },
    computed: {
        ...mapGetters({
            spaceData: "revManStore/getSpaceData",
            pricesData: "revManStore/getPricesData",
            properties: "propertiesStore/filtered",
            property: "revManStore/getSelectedProperty",
        }),
        actions_panel_list() {
                if(this.$vuetify.breakpoint.xs) {
                    return ['master_filter', 'export', 'save'];
                } else {
                    return ['advanced', 'master_filter', 'export', 'bulk_edit', 'columns', 'save', 'add_spaces'];
                }
            },
        hasSpaceData() {
            return Object.keys(this.spaceData)?.length;
        },
        hasPricesData() {
            return this.pricesData?.spaceGroupInfo ? true : false;
        },
    },
    methods: {
        ...mapMutations({
            setSpaceData: 'revManStore/SET_SPACE_DATA',
        }),
        ...mapActions({
            setProperty: "revManStore/setProperty",
        }),
        setPreviousData(data) {
        },
        onTabChange(value) {
                this.current_tab = value;
         },
        fetchData() {
            this.report_key++;
        },
        viewUnit(data) {
            this.$store.dispatch('navigationStore/openSlideOut', {
                component: 'unit',
                props: {
                    unit_id: data.unit_id
                }
            });
        }
    },
    watch: {}
}
</script>
<style lang="scss">

    .filter-adjustment-hb-report {
        .report-viewer-container {
            > div:nth-child(3) {
                > span > div > div > div {
                    > div:nth-child(1) {
                        width: 50%;
                        margin-bottom: 6px !important;
                        .hb-autocomplete-wrapper {
                            width: unset !important;
                        }
                    }
                    > div:nth-child(2) {
                        display: none;
                    }
                    > div:nth-child(3) {
                        margin-bottom: 6px !important;
                        width: 50%;
                        .hb-text-field-wrapper {
                            width: unset !important;
                        }
                    }
                    > div:nth-child(4) {
                        margin-bottom: 0px !important;
                        margin-top: 0px !important;
                    }
                }
            }
        }
    }

    .filter-adjustment-hb-report-map {
        .report-viewer-container {
            > div:nth-child(3) {
                > span > div {
                    span:nth-child(1) {
                        display: flex;
                        width: 100% !important;
                    }
                    span > div:nth-child(1) {
                        max-width: 100% !important;
                        margin-bottom: 6px !important;
                        width: 100% !important;
                        .hb-autocomplete-wrapper {
                            width: unset !important;
                        }
                    }
                }
            }
        }
    }
</style>
<style>
.table-row {
    cursor: pointer;
}

.message-preview {
    height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
    display: block;
}

.table-cell {
    position: relative;
}

.unit {
    color: #00b2ce;
}

.table-row.disabled {
    opacity: .25;
}




    .property-list-result .subdued,
    .property-list-result{
        line-height: 14px;
    }
    .blade-body-content {
        height: 45vh;
        overflow-y: scroll;
        max-height: 45vh;
    }
    .custom-border {
        border-bottom: 2px solid #dce8ef;
    }



</style>
