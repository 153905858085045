<template>
<hb-modal size="medium" :title="iseditSellRate ? 'Edit Sell Rate' : 'Edit Set Rate'" v-model="open_modal"
            @close="closeModel">
            <template v-slot:content>
                <div class="modelHeight">
                    <v-row class="hb-table-row pa-0 ma-0">
                        <v-col class="hb-table-label py-4 pl-6" cols="4">
                            {{ iseditSellRate ? 'Sell Rate' : 'Set Rate' }} 
                        </v-col>
                        <v-col cols="8" class="pl-6 pt-1 pb-1">
                            <v-row v-if="!hasPermission('edit_spacegroup_rate')">
                                <v-col cols="12" sm="4" class="hb-table-value">
                                    {{ iseditSellRate ? (pricesData?.spaceGroupInfo?.spacegroup_sell_rate != null ? pricesData?.spaceGroupInfo?.spacegroup_sell_rate | formatMoney : '' ) : 
                                    (pricesData?.spaceGroupInfo?.spacegroup_set_rate != null ? pricesData?.spaceGroupInfo?.spacegroup_set_rate  | formatMoney : '' ) }}
                                </v-col>
                            </v-row>
                            <v-row v-if="hasPermission('edit_spacegroup_rate') && iseditSetRate">
                                <v-col cols="12" sm="6" class="hb-table-value">
                                    <HbTextField name="set_rate" id="set_rate" label="Rate" prefix="$"
                                        v-validate="'required|decimal:2|min_value:0|max_value:999999'"
                                        v-model.lazy="rates.set_rate" data-vv-as="Set Rate"
                                        :error-messages="errors.collect('set_rate')"
                                        :hide-details="!errors.collect('set_rate').length" dense flat>
                                    </HbTextField>
                                </v-col>
                            </v-row>
                            <v-row v-if="hasPermission('edit_spacegroup_rate') && iseditSellRate">
                                <v-col cols="12" sm="6" class="hb-table-value">
                                    <HbTextField name="price" id="price" label="Price" prefix="$"
                                        v-validate="'required|decimal:2|min_value:0|max_value:999999'"
                                        v-model.lazy="rates.price" data-vv-as="unit number"
                                        :error-messages="errors.collect('price')"
                                        :hide-details="!errors.collect('price').length" dense flat>
                                    </HbTextField>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row> 
                </div>
            </template>
            <template v-slot:left-actions>
            </template>
            <template v-slot:right-actions>
                <hb-btn @click="handleSaveEditRate">Save</hb-btn>
            </template>
        </hb-modal>
</template>

<script type="text/babel">

import api from "../../../assets/api.js";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import { mapMutations, mapGetters, mapActions } from "vuex"

export default {
    name: "SellRateSetRateModal",
    data() {
        return {
            open_modal: false,
            rates: {
                set_rate: '',
                price: '',
            },
        }
    },
    created() {
    },
    mixins: [notificationMixin],
    inject: ['fetchData'], 
    computed: {
        ...mapGetters({
            pricesData: "revManStore/getPricesData",
            hasPermission: 'authenticationStore/rolePermission'
        }),
        iseditSetRate() {
            this.rates.set_rate = '';
            if (this.pricesData?.sellRate === null) {
                this.rates.set_rate = this.pricesData?.setRate;
                return true;
            }
            return false;
        },
        iseditSellRate() {
            this.rates.price = '';
            if (this.pricesData?.setRate === null) {
                this.rates.price = this.pricesData?.sellRate;
                return true;
            }
            return false;
        },
    },
    methods: {
        ...mapMutations({
            setPrices: "revManStore/SET_PRICES",
        }),
        closeModel() {
            this.open_modal = false;
            this.setPrices({});
        },
        updateSpaceGroupSettings(property_id, spacegroupIdHash, reqBody) {
        return api
            .put(
            this,
            api.getSpaceGroupRateManagementUrl(property_id, spacegroupIdHash),
            reqBody,
            "",
            false
            )
            .then(() => {
            this.showMessageNotification({ type: 'success', description: `${this.iseditSellRate ? 'Sell Rate Updated Successfully' : 'Set Rate Updated Successfully'}` });
            })
            .catch((err) => {
            if (err.status === 403) {
                this.showMessageNotification({ type: 'error', description: "You don't have enough permissions to update this configuration." });
            } else this.showMessageNotification(`Error while Updating ${this.iseditSellRate ? 'Sell Rate' : 'Set Rate' }`);
            });
        },
        async handleSaveEditRate(){
                if(this.iseditSetRate) {
                    await this.$validator.validate('set_rate'); 
                }
                if(this.iseditSellRate) {
                    await this.$validator.validate('price'); 
                }

            let data = {};

                if(this.iseditSellRate) {
                    if (Number(this.rates.price) != this.pricesData.sellRate && Number(this.rates.price) != '') {
                                data.sell_rate = Number(this.rates.price);
                    }
                } else {
                    if (Number(this.rates.set_rate) != this.pricesData.setRate && Number(this.rates.set_rate) != '') {
                                data.set_rate = Number(this.rates.set_rate);
                    }
                }
                if (Object.keys(data).length > 0) {
                    if(!data?.sell_rate) {
                    data.sell_rate = this.pricesData?.spaceGroupInfo?.spacegroup_sell_rate;
                    }
                    if(!data?.set_rate) {
                    data.set_rate = this.pricesData?.spaceGroupInfo?.spacegroup_set_rate;
                    }  
                    if(!this.pricesData?.spaceGroupInfo?.spacegroup_rate_plan_id){
                        this.showMessageNotification({description: "Rate Plan is Not Assigned to a Unit Group"})
                    } else {
                    await this.updateSpaceGroupSettings(this.pricesData.propertyId , this.pricesData?.spaceGroupInfo?.spacegroup_id_hash, {
                    ...data,
                    spacegroup_id: this.pricesData?.spaceGroupInfo?.spacegroup_id,
                    active: Boolean(this.pricesData?.spaceGroupInfo?.spacegroup_rate_management_active),
                    rate_plan_id: this.pricesData?.spaceGroupInfo?.spacegroup_rate_plan_id,
                });
              }
            }
            this.open_modal = false;
            this.setPrices({});
            data = {};
            this.fetchData(); 
        },
    },
    watch: {
        pricesData: {
                handler(newval) {
                    if (Object.keys(newval)?.length) {
                    this.open_modal = true;
                    } else {
                    this.open_modal = false;
                    }
                },
                immediate: true,
            },
    }
}
</script>
<style lang="scss">
.modelHeight {
    height: 200px;
}
</style>